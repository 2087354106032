import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { connect } from "react-redux";

import {
  Typography,
  Button,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  SnackbarContent,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Slider,
  Dialog,
  DialogContent,
} from "@material-ui/core";

import BidConfirmPage from "../components/BidConfirmPage";
import Loading from "../components/Loading";
import BidHistory from "../components/BidHistory";
import CarfaxNoDataVin from "../components/CarfaxNoDataVin";

import ReactSVG from "react-svg";
import circleDDOIcon from "../assets/circleDDOIcon.svg";
import carfaxLogo from "../assets/carfaxLogo.svg";
import autoCheckLogo from "../assets/autoCheckLogo.svg";

import { hardBid, autoBid } from "../services/BidServices";
import { transportquote } from "../services/UtilityServices";
import { formatPhoneNumber } from "../services/HelperServices";

import {
  watchlist_count,
  remove_selected_mobile_car,
} from "../actions/carActions";
import CarReport from "../components/CarReport";
import AutoCheckReport from "../components/AutoCheckReport";

const styles = (theme) => ({
  root: {
    width: "70%",
    margin: "auto",
  },
  rootButton: {
    width: "70%",
    margin: "auto",
    textAlign: "right",
  },
  buyHeader: {
    display: "flex",
    marginTop: "1.5rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buyWrapper: {
    display: "flex",
    marginTop: "1.5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    flexDirection: "column",
    height: "inherit",
    [theme.breakpoints.down("md")]: {
      marginTop: ".5rem",
      height: "59%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: 0,
      marginBottom: "6rem",
      minHeight: "100vh",
      marginTop: "3.5rem",
    },
  },
  upperWrapper: {
    display: "flex",
  },
  buyPriceWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      marginBottom: "3rem",
    },
  },
  carInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "36%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  lowerLeftWrapper: {
    marginRight: "2rem",
    marginLeft: "3rem",
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    width: "30rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      margin: 0,
      alignItems: "center",
    },
  },
  lowerLeftTitle: {
    fontSize: "2rem",
    color: theme.palette.DDO.main,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lowerVehicleInfoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
    },
  },
  paymentTransportWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "35rem",
    [theme.breakpoints.down("md")]: {
      width: "28rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      margin: 0,
    },
  },
  carReportWrapper: {
    display: "flex",
    flexDirection: "column",
    marginRight: "1rem",
  },
  carInfoTitle: {
    fontSize: "1.5rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
    },
  },
  infoWrapper: {
    width: "60%",
    position: "relative",
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "75%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      alignItems: "center",
      marginTop: ".25rem",
    },
  },
  lineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: ".2rem",
    borderBottom: `1px solid ${theme.palette.secondary.gray}`,
    paddingBottom: ".2rem",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {},
  },
  paymentLineWrapper: {
    display: "flex",
    marginBottom: ".75rem",
    paddingBottom: ".2rem",
    minWidth: "8rem",
    maxWidth: "20rem",
    alignItems: "center",
  },
  bolded: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginLeft: ".5rem",
    },
  },
  boldedLarge: {
    fontWeight: "bold",
    color: theme.palette.primary.gray,
  },
  boldedRedText: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.primary.redHighlight,
  },
  DDOBoldedColor: {
    fontSize: theme.font.small,
    fontWeight: "bold",
    color: theme.palette.DDO.main,
  },
  DDOPricingInfoHeader: {
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.DDO.main,
    marginBottom: "2rem",
  },
  programLink: {
    textDecoration: "underline",
    color: "blue",
    margin: 0,
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
    },
  },
  promoWrapper: {
    display: "flex",
    flexWrap: "none",
  },
  carReportLink: {
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
    height: "1rem",
  },
  DDOButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "8rem",
      marginRight: 0,
      "&:hover": {
        backgroundColor: theme.palette.DDO.main,
        "@media (hover: none)": {
          backgroundColor: theme.palette.DDO.main,
          "&:active": {
            backgroundColor: theme.palette.DDO.main,
          },
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
    },
  },
  DDOCancelButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.DDO.main,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
    [theme.breakpoints.down("md")]: {
      width: "8rem",
      marginRight: 0,
      "&:hover": {
        backgroundColor: theme.palette.secondary.gray,
        "@media (hover: none)": {
          backgroundColor: theme.palette.secondary.gray,
          "&:active": {
            backgroundColor: theme.palette.secondary.gray,
          },
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: 0,
      backgroundColor: theme.palette.secondary.gray,
    },
  },
  unselectedButton: {
    color: theme.palette.button.text,
    margin: ".5rem",
    backgroundColor: theme.palette.primary.gray,
    borderRadius: 0,
    height: "2.5rem",
    boxShadow: "none",
    width: "9rem",
  },
  systemMessage: {
    fontSize: ".5rem",
    position: "absolute",
    top: "1.4rem",
    right: "2rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  systemMessage2: {
    fontSize: ".5rem",
    position: "absolute",
    top: "7.3rem",
    color: theme.palette.primary.gray,
    right: "2rem",
    [theme.breakpoints.down("md")]: {
      width: "10rem",
      right: "initial",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buyNowSystemMessage: {
    fontSize: ".5rem",
    position: "absolute",
    top: "6.4rem",
    right: "2rem",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buyNowSystemMessage2: {
    fontSize: ".5rem",
    position: "absolute",
    top: "8.7rem",
    color: theme.palette.primary.gray,
    right: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  checkboxRoot: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
  },
  priceWrapper: {
    display: "flex",
  },
  deliveryEstimateWrapper: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "14rem",
    },
  },
  paymentSelectionWrapper: {
    marginTop: ".5rem",
    marginBottom: ".5rem",
    minWidth: "15rem",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      minWidth: "9rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 0,
      minWidth: "initial",
      marginLeft: ".5rem",
      display: "flex",
      flexDirection: "column",
      minHeight: "5rem",
      marginTop: "1rem",
    },
  },
  paymentSelectionTitle: {
    marginBottom: ".5rem",
    fontWeight: "bold",
  },
  transportSelectionWrapper: {
    marginTop: ".5rem",
    marginBottom: ".5rem",
    minWidth: "20rem",
    overflowY: "auto",
    maxHeight: "15rem",
  },
  DDOIcon: {
    width: "4rem",
    height: "2.4rem",
  },
  titleDDOTextWrapper: {
    borderBottom: `5px solid ${theme.palette.DDO.main}`,
    height: "2rem",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  titleDDOText: {
    color: theme.palette.DDO.main,
    fontSize: ".8rem",
  },
  titleWrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  stepper: {
    width: "75%",
    padding: 0,
  },
  stepLabel: {
    fontSize: theme.stepper.label.fontSize,
  },
  DDOStepIcon: {
    fill: theme.palette.DDO.main,
    color: theme.palette.DDO.main,
  },
  DDOCompletedIcon: {
    fill: theme.palette.DDO.main,
    color: theme.palette.DDO.main,
  },
  active: {},
  checked: {},
  checkboxRootDDO: {
    padding: 0,
    height: "1rem",
    paddingLeft: ".5rem",
    "&$checked": {
      color: theme.palette.DDO.main,
    },
  },
  acceptanceText: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
  },
  PDALink: {
    color: theme.palette.primary.gray,
  },
  label: {
    color: theme.palette.primary.gray,
  },
  groupLabelRed: {
    color: theme.palette.primary.redHighlight,
  },
  imageWrapper: {
    display: "flex",
    height: "6rem",
    marginRight: "3rem",
    width: "60%",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      marginRight: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  vehicleImage: {
    width: "18%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  errorMessage: {},
  buttonWrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      display: "flex",
      width: "100vw",
    },
  },
  sliderWrapper: {
    marginTop: ".8rem",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      margin: 0,
    },
  },
  sliderValueWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: ".5rem",
  },
  sliderStartValue: {
    position: "relative",
    right: "1.75rem",
    fontSize: ".7rem",
  },
  sliderEndValue: {
    position: "relative",
    left: "1.75rem",
    fontSize: ".7rem",
  },
  sliderTrack: {
    backgroundColor: theme.palette.DDO.main,
  },
  sliderThumb: {
    backgroundColor: theme.palette.DDO.main,
    "&:focus,&:hover,&$active": {
      boxShadow: `${theme.palette.DDO.main} 0 0 5px 5px`,
    },
  },
  selectArrow: {
    display: "flex",
  },
  winning: {
    color: theme.palette.DDO.main,
    fontWeight: "bold",
    fontSize: theme.font.small,
  },
  losing: {
    color: theme.palette.primary.redHighlight,
    fontWeight: "bold",
    fontSize: theme.font.small,
  },
  DDOClickableText: {
    color: theme.palette.DDO.main,
    textDecoration: "underline",
    fontSize: "76%",
    cursor: "pointer",
    "&:hover": {
      opacity: ".5",
    },
  },
  cardText: {
    fontSize: "76%",
    color: theme.palette.primary.gray,
    width: "13rem",
  },
  progressBarWrapper: {
    display: "flex",
  },
  circularProgress: {
    margin: ".5rem",
    height: "8rem",
  },
  circularProgressBar: {
    width: "5rem",
  },
  timeDisplay: {
    fontWeight: "bold",
    fontSize: "1rem",
    color: theme.palette.primary.gray,
  },
  interiorText: {
    fontSize: ".8rem",
    marginBottom: "1rem",
    color: theme.palette.primary.gray,
  },
  card: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  DDOCardTitle: {
    color: theme.palette.DDO.main,
    fontWeight: "bold",
    fontSize: ".8rem",
  },
  summaryWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "13rem",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      right: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "6rem",
      right: 0,
    },
  },
  errorSnackBar: {
    marginTop: "7rem",
  },
  bidSelectContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    "@supports (-ms-ime-align:auto)": {
      justifyContent: "space-around",
    },
  },
  formControl: {
    width: "100%",
  },
  radioRoot: {
    color: theme.palette.primary.gray,
  },
  colorPrimary: {
    "&$checked": {
      color: theme.palette.DDO.main,
      "&:hover": {
        backgroundColor: fade(
          theme.palette.DDO.main,
          theme.palette.action.hoverOpacity
        ),
      },
    },
  },
  NOSMessage: {
    fontSize: theme.font.small,
    color: theme.palette.primary.gray,
    marginTop: ".25rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".5rem",
    },
  },
  dialogPaper: {
    minHeight: "31vh",
    maxHeight: "31vh",
    maxWidth: "50%",
    width: "50%",
  },
  noOfBidText: {
    fontWeight: "bold",
    color: theme.palette.grounding.main,
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      opacity: ".5",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  },
});

function getSteps() {
  return ["Enter Bid Information", "Confirm Bid"];
}

const checksAllowed = ["19060", "48055"];

export class BidVehicle extends Component {
  state = {
    bidAmount: 0,
    transport: "",
    paymentOptionSelected: "",
    selectedLocation: null,
    timeRemaining: 0,
    bidType: "autoBid",
    error: null,
    activeStep: 0,
    changeLocation: false,
    loading: true,
    marks: [],
    showCarfaxReport: false,
    showAutoCheckReport: false,
    showBidHistory: false,
    showCarfaxSnackBar: false,
  };

  componentWillMount = () => {
    const { accountSetting, vehicleBidInformation, car } = this.props;
    const userLocation = accountSetting.custLocations.filter(
      (location) =>
        JSON.stringify(location.locationId) === accountSetting.selectedLocation
    )[0];
    this.getTransportQuote(userLocation);
    const bidChoices = this.renderBidChoices();
    this.setState({
      marks: this.setupMarks(),
      selectedLocation: userLocation.locationId,
      bidChoices,
      bidAmount: vehicleBidInformation.currentBid
        ? vehicleBidInformation.currentBid + 100
        : vehicleBidInformation.discountedStartPrice !== undefined
        ? vehicleBidInformation.discountedStartPrice
        : vehicleBidInformation.startPrice,
      transport: car.groundingDealer ? "Buyer Arranged" : "",
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { accountSetting } = this.props;
    const { selectedLocation } = this.state;
    if (
      prevState.selectedLocation !== selectedLocation &&
      prevState.selectedLocation
    ) {
      const userLocation = accountSetting.custLocations.filter(
        (location) => location.locationId === selectedLocation
      )[0];
      this.getTransportQuote(userLocation);
    }
  };

  getTransportQuote = async (userLocation) => {
    const { car, user } = this.props;
    const transportQuote = await transportquote(
      car.inventoryId,
      user.userId,
      car.saleEventItemId,
      userLocation.locationDTO.addressDTO.zip,
      userLocation.locationId
    );
    if (
      transportQuote.length > 0 &&
      (transportQuote[0].quote === 0.0 || transportQuote[0].quote)
    ) {
      this.setState({
        transportQuote: transportQuote[0],
        loading: false,
      });
    } else {
      this.setState({
        transportQuote: null,
        loading: false,
      });
    }
  };

  verifyQuotePrice = (quote) => {
    const { car } = this.props;
    if (car.programCode === "TAP") {
      return `$${quote.quoteWithPromo}`;
    } else {
      return quote.quote.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
  };

  renderBidChoices = () => {
    const { vehicleBidInformation } = this.props;
    const bidChoices = [];
    for (let i = 0; i <= 50; i++) {
      bidChoices.push(
        vehicleBidInformation.discountedStartPrice !== undefined
          ? vehicleBidInformation.discountedStartPrice + i * 100
          : vehicleBidInformation.startPrice + i * 100
      );
    }
    const filterBidChoices = bidChoices.filter(
      (choice) =>
        choice >=
        (vehicleBidInformation.currentBid
          ? vehicleBidInformation.currentBid + 100
          : vehicleBidInformation.discountedStartPrice !== undefined
          ? vehicleBidInformation.discountedStartPrice
          : vehicleBidInformation.startPrice)
    );
    return filterBidChoices;
  };

  setupMarks = () => {
    const { vehicleBidInformation } = this.props;
    let bidMakrs = [];
    for (let i = 0; i <= 50; i++) {
      bidMakrs[i] = {
        value:
          vehicleBidInformation.discountedStartPrice !== undefined
            ? vehicleBidInformation.discountedStartPrice + i * 100
            : vehicleBidInformation.startPrice + i * 100,
      };
    }
    return bidMakrs;
  };

  handlePayment = (event) => {
    this.setState({
      paymentOptionSelected: event.target.value,
      paymentError: false,
    });
  };

  handleTransport = (event) => {
    this.setState({
      transport: event.target.value,
      transportError: false,
    });
  };

  toggleChangeLocation = (evt) => {
    this.setState({
      changeLocation: false,
      selectedLocation: Number(evt.target.value),
    });
  };

  confirmOpen = () => {
    this.setState({
      confirmPage: true,
    });
  };

  verifySelections = () => {
    const { transport, paymentOptionSelected } = this.state;
    const { car } = this.props;
    if ((transport || car.groundingDealer) && paymentOptionSelected) {
      return true;
    } else {
      this.setState({
        transportError: !transport && !car.groundingDealer,
        paymentError: !paymentOptionSelected,
      });
    }
  };

  submitBid = async () => {
    const {
      bidType,
      bidAmount,
      paymentOptionSelected,
      transport,
      transportQuote,
      selectedLocation,
    } = this.state;
    const {
      user,
      vehicleBidInformation,
      handleBidOpen,
      car,
      updateAutoBid,
      watchlistCount,
      dispatch,
      mobileCarInformation,
    } = this.props;
    const paymentOptionCode =
      vehicleBidInformation.dealershipPaymentInfoDto.paymentOptionDTOCollection.filter(
        (option) => option.optionCode === paymentOptionSelected
      )[0].paymentOptionId;
    const chosenTransportQuote =
      car.programCode === "TAP"
        ? Number(transportQuote.quoteWithPromo)
        : transportQuote.quote;
    if (bidType === "hardBid") {
      const bid = await hardBid(
        vehicleBidInformation,
        user,
        bidAmount,
        paymentOptionCode,
        transport,
        chosenTransportQuote,
        selectedLocation
      );
      if (bid.status !== "false") {
        if (!car.bidPlaced && !car.myCurrentAutoBid && !car.isInWatchList) {
          dispatch(watchlist_count(watchlistCount + 1));
        }
        handleBidOpen(true);
        if (mobileCarInformation) {
          dispatch(remove_selected_mobile_car());
        }
      } else {
        this.setState({
          error: "Bid failed to process.",
        });
      }
    } else {
      const response = await autoBid(
        vehicleBidInformation,
        user,
        bidAmount,
        paymentOptionCode,
        transport,
        chosenTransportQuote,
        selectedLocation
      );
      if (response.status !== "false") {
        if (!car.bidPlaced && !car.myCurrentAutoBid && !car.isInWatchList) {
          dispatch(watchlist_count(watchlistCount + 1));
        }
        updateAutoBid(car.vin, bidAmount);
        if (mobileCarInformation) {
          dispatch(remove_selected_mobile_car());
        }
      } else {
        this.setState({
          error: response.message,
        });
      }
    }
  };

  selectBidType = (evt) => {
    this.setState({
      bidType: evt.target.value,
    });
  };

  renderStepper = (activeStep, steps) => {
    const { classes, page } = this.props;
    return (
      <div className={classes.buyHeader}>
        <div className={classes.titleWrapper}>
          <img
            id="DDOIcon"
            className={classes.DDOIcon}
            src={circleDDOIcon}
            alt="DDO Icon"
          />
          <div className={classes.titleDDOTextWrapper}>
            <Typography
              className={classes.titleDDOText}
              style={{ fontWeight: "bold" }}
            >
              Dealer Direct Online
            </Typography>
          </div>
        </div>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                classes={{ alternativeLabel: classes.stepLabel }}
                StepIconProps={{
                  classes: {
                    root: classes.stepRoot,
                    active:
                      page === "GAMD"
                        ? classes.GAMDStepIcon
                        : classes.DDOStepIcon,
                    completed:
                      page === "GAMD"
                        ? classes.GAMDCompletedIcon
                        : classes.DDOCompletedIcon,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  };

  renderStepperButton = (activeStep, steps) => {
    const { purchaseErrorMessage } = this.state;
    const { classes, handleBidOpen, user } = this.props;
    const errorMessage = `Purchase could not be completed at this time, please contact Dealer Direct support 877-522-3372 for assistance.`;
    return (
      <div className={classes.buttonWrapper}>
        <Snackbar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={purchaseErrorMessage}
          autoHideDuration={2000}
          className={classes.errorSnackBar}
          onClose={() => this.setState({ purchaseErrorMessage: false })}
        >
          <SnackbarContent
            className={classes.errorMessage}
            message={errorMessage}
          />
        </Snackbar>
        <Button
          className={classes.DDOButton}
          id="openConfirm"
          onClick={() =>
            activeStep === 0 ? this.nextStep() : this.submitBid()
          }
        >
          {activeStep === 0 ? `Continue` : `Confirm Bid`}
        </Button>
        {activeStep === 1 ? (
          <Button
            className={classes.DDOButton}
            id="backButton"
            onClick={() => this.prevStep()}
          >
            Back
          </Button>
        ) : null}
        <Button
          className={classes.DDOCancelButton}
          id="cancelButton"
          onClick={() => handleBidOpen("cancel")}
        >
          Cancel
        </Button>
      </div>
    );
  };

  nextStep = () => {
    if (this.verifySelections()) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };

  prevStep = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  showCarfaxReport = () => {
    this.setState({
      showCarfaxReport: !this.state.showCarfaxReport,
    });
  };

  showAutoCheckReport = () => {
    this.setState({
      showAutoCheckReport: !this.state.showAutoCheckReport,
    });
  };

  renderTransport = () => {
    const {
      selectedLocation,
      changeLocation,
      transport,
      transportQuote,
      transportError,
    } = this.state;
    const { car } = this.props;
    const { classes, accountSetting } = this.props;
    const carsArriveDisabled = !transportQuote || transportQuote.price === 0;

    switch (changeLocation) {
      case true:
        return (
          <div
            id="transportSelectionWrapper"
            className={classes.transportSelectionWrapper}
          >
            <Typography className={classes.paymentSelectionTitle}>
              Transportation Options -{" "}
              <span className={classes.cardText}>(Please select one)</span>
            </Typography>
            {accountSetting.custLocations.map((location) => {
              const { city, state, zip, addressLine1 } =
                location.locationDTO.addressDTO;
              const { businessPhone, contactName } =
                location.locationDTO.customerContactDTOs[0].communicationDTO;
              return (
                <div className={classes.paymentLineWrapper}>
                  <Checkbox
                    id={`${location.locationDTO.name}Checkbox`}
                    classes={{
                      root: classes.checkboxRootDDO,
                      checked: classes.checked,
                    }}
                    onClick={(evt) => this.toggleChangeLocation(evt)}
                    value={location.locationId}
                    checked={selectedLocation === location.locationId}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      style={{ marginLeft: ".5rem" }}
                      className={classes.DDOBoldedColor}
                    >
                      {`${location.locationDTO.name}`}
                    </Typography>
                    <Typography
                      style={{ marginLeft: ".5rem" }}
                      className={classes.DDOBoldedColor}
                    >
                      {`${addressLine1} ${city}, ${state} ${zip}`}
                    </Typography>
                    <Typography
                      style={{ marginLeft: ".5rem" }}
                      className={classes.DDOBoldedColor}
                    >
                      {`${contactName} - ${formatPhoneNumber(businessPhone)}`}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </div>
        );
      default:
        return (
          <div
            id="paymentSelectionWrapper"
            className={classes.paymentSelectionWrapper}
          >
            <Typography
              className={classes.paymentSelectionTitle}
              style={transportError ? { color: "red" } : null}
            >
              Transportation
            </Typography>
            <div className={classes.paymentLineWrapper}>
              <div className={classes.priceWrapper}>
                <Checkbox
                  id="carsArriveCheckbox"
                  classes={{
                    root: classes.checkboxRootDDO,
                    checked: classes.checked,
                  }}
                  onClick={this.handleTransport}
                  value={"CarsArrive"}
                  checked={transport === "CarsArrive"}
                  disabled={carsArriveDisabled || car.groundingDealer}
                />
                <Typography
                  style={{ marginLeft: ".5rem" }}
                  className={classes.DDOBoldedColor}
                >
                  {`CarsArrive ${
                    !carsArriveDisabled
                      ? `- ${this.verifyQuotePrice(transportQuote)}`
                      : `- Unavailable, Call 866-457-2277`
                  }`}
                </Typography>
              </div>
            </div>
            {!carsArriveDisabled ? (
              <div className={classes.paymentLineWrapper}>
                <div className={classes.deliveryEstimateWrapper}>
                  <Typography className={classes.cardText}>
                    <span className={classes.DDOBoldedColor}>
                      Delivery Estimate
                    </span>{" "}
                    {` - ${transportQuote.daysRequired} days`}
                  </Typography>
                  <Typography className={classes.cardText}>
                    To change delivery location, please{" "}
                    <span
                      className={classes.DDOClickableText}
                      onClick={() => this.setState({ changeLocation: true })}
                    >
                      click here
                    </span>
                  </Typography>
                </div>
              </div>
            ) : null}
            <div className={classes.paymentLineWrapper}>
              <div className={classes.priceWrapper}>
                <Checkbox
                  id="buyerArrangedCheckbox"
                  classes={{
                    root: classes.checkboxRootDDO,
                    checked: classes.checked,
                  }}
                  onClick={this.handleTransport}
                  value={"Buyer Arranged"}
                  checked={transport === "Buyer Arranged"}
                />
                <Typography
                  style={{ marginLeft: ".5rem" }}
                  className={classes.DDOBoldedColor}
                >
                  {"Buyer Arranged"}
                </Typography>
              </div>
            </div>
          </div>
        );
    }
  };

  toggleBidHistory = () => {
    this.setState({
      showBidHistory: !this.state.showBidHistory,
    });
  };

  renderPurchaseForm = () => {
    const { car, classes, vehicleBidInformation, user, pageFrom } = this.props;
    const {
      bidAmount,
      paymentOptionSelected,
      bidChoices,
      bidType,
      activeStep,
      steps,
      changeLocation,
      marks,
      paymentError,
    } = this.state;
    const radioClasses = {
      root: classes.radioRoot,
      colorPrimary: classes.colorPrimary,
      checked: classes.checked,
    };
    return (
      <React.Fragment>
        <div id="buyPriceWrapper" className={classes.buyPriceWrapper}>
          <div className={classes.lowerLeftWrapper}>
            <Typography className={classes.lowerLeftTitle}>
              Bid Information
            </Typography>
            <div className={classes.paymentTransportWrapper}>
              <div className={classes.paymentSelectionWrapper}>
                <Typography
                  className={classes.paymentSelectionTitle}
                  style={paymentError ? { color: "red" } : null}
                >
                  Payment Method
                </Typography>
                <div className={classes.paymentLineWrapper}>
                  <div className={classes.priceWrapper}>
                    <Checkbox
                      id="ACHCheckbox"
                      classes={{
                        root: classes.checkboxRootDDO,
                        checked: classes.checked,
                      }}
                      onClick={this.handlePayment}
                      value={"ACH"}
                      checked={paymentOptionSelected === "ACH"}
                    />
                    <Typography
                      style={{ marginLeft: ".5rem" }}
                      className={classes.DDOBoldedColor}
                    >
                      {"ACH"}
                    </Typography>
                  </div>
                </div>
                <div className={classes.paymentLineWrapper}>
                  <div className={classes.priceWrapper}>
                    <Checkbox
                      id="FPCheckbox"
                      classes={{
                        root: classes.checkboxRootDDO,
                        checked: classes.checked,
                      }}
                      onClick={(evt) => this.handlePayment(evt)}
                      value={"FP"}
                      checked={paymentOptionSelected === "FP"}
                      disabled={
                        vehicleBidInformation.dealershipPaymentInfoDto
                          .creditLimit -
                          bidAmount <=
                        0
                      }
                    />
                    <Typography
                      style={{ marginLeft: ".5rem" }}
                      className={classes.DDOBoldedColor}
                    >
                      {user.franchise === "TBSG"
                        ? `TFS Floor Plan`
                        : `LFS Floor Plan`}
                    </Typography>
                  </div>
                </div>
                {checksAllowed.includes(user.dealerNumber) ? (
                  <div className={classes.paymentLineWrapper}>
                    <div className={classes.priceWrapper}>
                      <Checkbox
                        id="CheckCheckbox"
                        classes={{
                          root: classes.checkboxRootDDO,
                          checked: classes.checked,
                        }}
                        onClick={(evt) => this.handlePayment(evt)}
                        value={"CHQ"}
                        checked={paymentOptionSelected === "CHQ"}
                      />
                      <Typography
                        style={{ marginLeft: ".5rem" }}
                        className={classes.DDOBoldedColor}
                      >{`Check`}</Typography>
                    </div>
                  </div>
                ) : null}
                {!changeLocation ? (
                  <Typography className={classes.systemMessage2}>
                    *Dealer may be responsible for outstanding fees/penalties
                    for CA registered vehicles.
                  </Typography>
                ) : null}
              </div>
              {this.renderTransport()}
            </div>

            {vehicleBidInformation.inventoryDTO.vehicleTitleDelay ? (
              <Typography className={classes.NOSMessage}>
                Vehicle has been grounded as an Early Termination pending Notice
                of Sale to the lessee. As a result, release of title for
                purchase may be delayed and is conditional on expiration of the
                Notice of Sale
              </Typography>
            ) : null}
          </div>
          <div className={classes.rightWrapper}>
            <div className={classes.infoWrapper}>
              <div className={classes.lineWrapper}>
                <Typography className={classes.bolded}>
                  High Bidder:{" "}
                </Typography>
                <Typography
                  className={
                    vehicleBidInformation.isUserWinning
                      ? classes.winning
                      : classes.losing
                  }
                >
                  {" "}
                  {vehicleBidInformation.highBidder}{" "}
                </Typography>
              </div>
              <div className={classes.lineWrapper}>
                <Typography className={classes.bolded}>Start Price:</Typography>
                <Typography className={classes.boldedRedText}>
                  {vehicleBidInformation.discountedStartPrice !== undefined
                    ? vehicleBidInformation.discountedStartPrice.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }
                      )
                    : vehicleBidInformation.startPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                </Typography>
              </div>
              <div className={classes.lineWrapper}>
                <Typography className={classes.bolded}>
                  Current Bid:{" "}
                  {pageFrom &&
                  (pageFrom === "watchList" || pageFrom === "DDO") &&
                  vehicleBidInformation.totalBids > 0 ? (
                    <span>
                      <span
                        className={classes.noOfBidText}
                        onClick={() => this.toggleBidHistory()}
                      >
                        {vehicleBidInformation.totalBids}
                      </span>{" "}
                      Bids
                    </span>
                  ) : (
                    <span> ({`${vehicleBidInformation.totalBids} Bids`})</span>
                  )}
                </Typography>
                <Typography className={classes.bolded}>
                  {car.currentBid ? car.currentBid : "No Bid Placed"}
                  {car.bidPlaced ? (
                    vehicleBidInformation.isUserWinning ? (
                      <span className={classes.winning}> WINNING</span>
                    ) : (
                      <span className={classes.losing}> LOSING</span>
                    )
                  ) : null}
                </Typography>
              </div>
              <div className={classes.lineWrapper}>
                <Typography className={classes.bolded}>
                  Your Current Autobid:
                </Typography>
                <Typography className={classes.bolded}>
                  {car.myCurrentAutoBid
                    ? car.myCurrentAutoBid.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : "$0"}
                </Typography>
              </div>
              <div className={classes.lineWrapper}>
                <Typography className={classes.bolded}>{`New ${
                  bidType === "autoBid" ? "AutoBid" : "Bid"
                } Amount:`}</Typography>
                <Typography className={classes.bolded}>
                  {bidAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </Typography>
              </div>
              <div className={classes.sliderWrapper}>
                <Slider
                  id="bidSlider"
                  aria-labelledby="discrete-slider-custom"
                  classes={{
                    track: classes.sliderTrack,
                    thumb: classes.sliderThumb,
                  }}
                  defaultValue={bidAmount}
                  max={bidChoices[bidChoices.length - 1]}
                  min={bidChoices[0]}
                  marks={marks}
                  onChange={(evt, value) => this.setState({ bidAmount: value })}
                  step={null}
                  value={bidAmount}
                />
                <div className={classes.sliderValueWrapper}>
                  <Typography className={classes.sliderStartValue}>
                    {bidChoices[0].toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </Typography>
                  <Typography className={classes.sliderEndValue}>
                    {bidChoices[bidChoices.length - 1].toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </Typography>
                </div>
              </div>
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  aria-label="bidSelect"
                  name="bidSelect"
                  className={classes.bidSelectContainer}
                  value={bidType}
                  onChange={this.selectBidType}
                  row
                >
                  <FormControlLabel
                    value="autoBid"
                    control={<Radio color="primary" classes={radioClasses} />}
                    label="Auto Bid"
                  />
                  <FormControlLabel
                    value="hardBid"
                    control={<Radio color="primary" classes={radioClasses} />}
                    label="Bid"
                    disabled={!vehicleBidInformation.hardBidButtonEnabled}
                  />
                </RadioGroup>
              </FormControl>
              {this.renderStepperButton(activeStep, steps)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderPage = () => {
    const {
      activeStep,
      bidAmount,
      transport,
      paymentOptionSelected,
      bidType,
      transportQuote,
      timeRemaining,
    } = this.state;
    const { user, vehicleBidInformation, car } = this.props;
    const steps = getSteps();
    switch (activeStep) {
      case 0:
        return this.renderPurchaseForm();
      case 1:
        return (
          <BidConfirmPage
            {...this.props}
            renderStepperButton={this.renderStepperButton}
            activeStep={activeStep}
            bidAmount={bidAmount}
            bidType={bidType}
            transport={transport}
            transportQuote={transportQuote}
            paymentOptionSelected={paymentOptionSelected}
            timeRemaining={timeRemaining}
            user={user}
            vehicleBidInformation={vehicleBidInformation}
            car={car}
          >
            {this.renderStepperButton(activeStep, steps)}
          </BidConfirmPage>
        );
      default:
        return null;
    }
  };
  showNoDataVin = () => {
    this.setState({
      showCarfaxSnackBar: true,
    });
  };

  hideCarFaxNoData = () => {
    this.setState({
      showCarfaxSnackBar: false,
    });
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  render() {
    const { classes, conditionReport, car, vehicleBidInformation, user } =
      this.props;
    const {
      error,
      activeStep,
      loading,
      showCarfaxReport,
      showAutoCheckReport,
      paymentError,
      transportError,
      showBidHistory,
      showCarfaxSnackBar,
    } = this.state;
    const steps = getSteps();
    return (
      <React.Fragment>
        {this.renderStepper(activeStep, steps)}
        {
          <div id="buyWrapper" className={classes.buyWrapper}>
            <div className={classes.upperWrapper}>
              <div className={classes.imageWrapper}>
                {conditionReport &&
                  conditionReport.inspectionImageDTO &&
                  conditionReport.inspectionImageDTO
                    .slice(0, 5)
                    .map((image) => {
                      return (
                        <img
                          key={image.inspectionImagesId}
                          src={image.imageUrl}
                          className={classes.vehicleImage}
                          alt=""
                        />
                      );
                    })}
              </div>
              <div className={classes.carInfoWrapper}>
                <Typography className={classes.carInfoTitle}>
                  {car.make} {car.model} {car.year}
                </Typography>
                <div className={classes.lowerVehicleInfoWrapper}>
                  <div className={classes.innerVehicleInfoWrapper}>
                    <Typography className={classes.label}>
                      {" "}
                      VIN:{" "}
                      <span className={classes.boldedLarge}>
                        {vehicleBidInformation.vin}
                      </span>
                    </Typography>
                    <Typography className={classes.label}>
                      {" "}
                      Odometer:{" "}
                      <span className={classes.boldedLarge}>
                        {vehicleBidInformation.mileage}
                      </span>
                    </Typography>
                  </div>
                  <div className={classes.carReportWrapper}>
                    <div id="carfaxLinkwrapper">
                      <ReactSVG
                        id="carfaxLink"
                        className={classes.carReportLink}
                        src={carfaxLogo}
                        alt="carfax"
                        onClick={() => this.showCarfaxReport()}
                        svgStyle={{ width: "5rem", height: "2rem" }}
                      />
                    </div>
                    <div id="autocheckLinkwrapper">
                      <ReactSVG
                        id="autocheckLink"
                        className={classes.carReportLink}
                        src={autoCheckLogo}
                        alt="autocheck"
                        onClick={() => this.showAutoCheckReport()}
                        svgStyle={{ width: "5rem", height: "2rem" }}
                        style={{ marginTop: ".5rem" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!loading ? this.renderPage() : <Loading />}
          </div>
        }
        {showBidHistory ? (
          <Dialog
            open={showBidHistory}
            onClose={() => this.toggleBidHistory()}
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogContent>
              <BidHistory
                saleEventItemId={car.saleEventItemId}
                from="BidVehicle"
              />
            </DialogContent>
          </Dialog>
        ) : null}
        {showCarfaxReport ? (
          <CarReport
            vin={car.vin}
            reportType="carfax"
            showCarfaxReport={this.showCarfaxReport}
            showNoDataVin={this.showNoDataVin}
            user={user}
          />
        ) : null}
        {showCarfaxSnackBar ? (
          <CarfaxNoDataVin hideCarFaxNoData={this.hideCarFaxNoData} />
        ) : null}
        {showAutoCheckReport ? (
          <AutoCheckReport
            vin={
              car.vehicleIdentificationNumber
                ? car.vehicleIdentificationNumber
                : car.vin
            }
            reportType="carfax"
            showAutoCheckReport={this.showAutoCheckReport}
            user={user}
          />
        ) : null}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={3000}
          open={error}
          onClose={() => this.setState({ error: null })}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{error}</span>}
          style={{ top: "10rem" }}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={transportError}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Please select transport method</span>}
          style={{ top: "13rem" }}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={paymentError}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Please select payment method</span>}
          style={{ top: "10rem" }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    watchlistCount: state.cars.watchlistCount,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(BidVehicle));
