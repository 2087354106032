import React, { Component } from "react";
import { connect } from "react-redux";
import ReactSVG from "react-svg";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  Snackbar,
  SnackbarContent,
  Link,
} from "@material-ui/core";

import Message from "../components/Message";
import groundNavIcon from "../assets/groundNavIcon.svg";
import hourglass from "../assets/hourglass.svg";
import searchIcon from "../assets/searchIcon.svg";
import lexusIcon from "../assets/lexus.ico";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import { amber } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";

import NonSafariIOSAppDownloadNoticeModal from "../components/NonSafariIOSAppDownloadNoticeModal";
import AppDownloadModal from "../components/AppDownloadModal";
import HomepageCarDisplay from "../components/HomepageCarDisplay";
import MessageCenter from "../components/MessageCenter";
import Loading from "../components/Loading";
import DealerPurchasePerformance from "../components/DealerPurchasePerformance";
import VINEntry from "../components/VINEntry";
import * as userAccess from "../services/UserRoles";
import { redeemMobileCode } from "../services/MobileCodeRedemptionService";
import { isIOS, isSafari, isMobileSafari } from "react-device-detect";

import {
  add_gamd_cars,
  add_search_cars,
  watchlist_count,
  remove_selected_mobile_car,
} from "../actions/carActions";
import {
  set_nav_location,
  hide_mobile_nav_menu,
  show_saved_search_page,
  set_vehicles_needing_attention,
  system_message_viewed,
  non_safari_mobile_app_download_notification_modal,
  mobile_app_download_instructions_modal,
} from "../actions/uiActions";

import { getGAMDVehicles } from "../services/GAMDServices";
import getSavedAutoSearchResults from "../services/getSavedAutoSearchResults";
import { getSavedSearch } from "../services/AdvanceSearchServices";
import * as LoginServices from "../services/LoginServices";
import * as ReportServices from "../services/ReportServices";
import * as WatchListServices from "../services/WatchListServices";
import * as GroundingServices from "../services/GroundingServices";
import {
  HOME,
  LBSG,
  TBSG,
  TOYOTA_DEALER_DIRECT,
  LEXUS_DEALER_DIRECT,
  TOYOTA,
  LEXUS,
  TDD,
  LDD,
  DLR_ACTION_NEEDED,
  GROUND_A_VEHICLE_HERE,
  VEHICLES_REQUIRING_ACTION,
  AUTOSEARCH_RESULTS,
  SUCCESS,
  GROUNDING_MESSAGES,
} from "../constants/UILabel";

import { API_URL, ROUTE_URL } from "../constants/Constant";

const styles = (theme) => ({
  homeWrapper: {
    display: "flex",
    position: "relative",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      overflowY: "auto",
      marginTop: "34%",
      height: "25rem",
      display: "block",
      marginBottom: "2rem",
    },
  },
  leftWrapper: {
    width: "60%",
    margin: ".5rem",
    display: "flex",
    flexDirection: "column",
    justifySelf: "flex-start",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "54%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      height: "19rem",
    },
  },
  rightWrapper: {
    width: "46%",
    margin: ".5rem",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "42%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      marginBottom: "4rem",
    },
  },
  vinEntryWrapper: {
    height: "10rem",
    display: "flex",
    flexDirection: "column",
    margin: "1rem",
    boxShadow: "0 1px 3px rgba(0,0,0,0.03), 0 1px 2px rgba(0,0,0,0.09)",
    [theme.breakpoints.down("md")]: {
      height: "8rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "6rem",
      margin: 0,
    },
  },
  carWrapper: {
    height: "9.75rem",
    display: "flex",
    flexDirection: "column",
    margin: "1rem",
    boxShadow: "0 1px 3px rgba(0,0,0,0.03), 0 1px 2px rgba(0,0,0,0.09)",
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "8rem",
      margin: 0,
      marginBottom: ".5rem",
    },
  },
  carouselWrapper: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
  },
  carDisplayText: {
    margin: ".5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: ".9rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".6rem",
    },
  },
  clickableLink: {
    margin: ".5rem",
    color: theme.palette.grounding.main,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
    },
  },
  carDisplayTextAutoSearch: {
    marginRight: ".5rem",
    marginBottom: ".2rem",
    marginTop: ".5rem",
    marginLeft: "0",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
    },
  },
  groundText: {
    margin: ".5rem",
    color: theme.palette.grounding.main,
  },
  carCarousel: {
    width: "100%",
    display: "flex",
    borderTop: `3px solid ${theme.palette.secondary.gray}`,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
    },
  },
  dealerPP: {
    height: "50%",
  },
  carousel: {
    height: "100%",
  },
  icon: {
    maxWidth: "1.25rem",
    marginRight: "1.25rem",
    marginLeft: "1.4rem",
    fill: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginTop: ".5rem",
    },
  },
  iconAutoSearch: {
    maxWidth: "1.25rem",
    marginRight: "1.25rem",
    marginLeft: "1.4rem",
    marginTop: ".4rem",
    fill: theme.palette.primary.gray,
    [theme.breakpoints.down("sm")]: {
      marginTop: ".5rem",
    },
  },
  groundIcon: {
    marginRight: "1.25rem",
    marginLeft: "1.4rem",
    fill: theme.palette.grounding.main,
  },
  titleWithIcon: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.light.gray,
      borderRadius: "2rem 2rem 0 0",
    },
  },
  groundTitleWithIcon: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  warning: {
    backgroundColor: amber[200],
    width: "95vw",
    borderRadius: 0,
    borderBottom: "3px solid goldenrod",
  },
  warningIcon: {
    fontSize: "2rem",
    marginRight: "2rem",
  },
  message: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.gray,
  },
  anchorOriginTopLeft: {
    top: "7rem",
  },
  systemMessageLabel: {
    width: "3rem !important",
  },
  saveSearchLink: {
    color: theme.palette.button.main,
    fontSize: "1rem",
    paddingBottom: ".2rem",
    paddingLeft: ".2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.font.small,
    },
  },
  vinInvalidError: {
    maxWidth: "100%",
    width: "max-content",
  },
});

export class Home extends Component {
  state = {
    searchCarLoad: false,
    attentionCarLoad: false,
    dealerPurchasePerformance: {},
    vin: "",
    errorMessage: "",
    vehiclesNeedingAttention: [],
    filteredSearchCars: [],
    showSystemMessage: false,
    searchCount: 0,
    showInvalidVinErrorMessage: false,
    invalidVINErrorMessage: "",
  };

  loadPriceQuoteScreen = () => {
    const { history } = this.props;
    const { vin } = this.state;
    return vin !== "" ? history.push(ROUTE_URL.PRICEQUOTE, vin) : "";
  };

  componentWillMount = () => {
    const { history, user, dispatch, navLocation, pageTheme, buyerView } =
      this.props;
    if (userAccess.isRideshareUser(user.userRoles)) {
      history.replace("/mobility");
    } else {
      if (navLocation !== HOME) {
        dispatch(set_nav_location(HOME));
      }
      if (user.franchise === LBSG && pageTheme !== LEXUS) {
        document.title = LEXUS_DEALER_DIRECT;
        document.getElementById("favicon").href = lexusIcon;
      }
      if (user.franchise === TBSG && pageTheme !== TOYOTA) {
        document.title = TOYOTA_DEALER_DIRECT;
      }
      if (user.resetPassword && !buyerView) {
        history.replace(ROUTE_URL.CHANGE_PASSWORD);
      }
      if (user.loginId) {
        this.getGAMDVehicles();
        this.storeVehicleSearch();
        // this.tempVehicleSearch()
        this.getDealerPurchasePerformance();
        this.loadWatchlistData();
      }
      if (Date.now() < user.systemMessageExpirationDate) {
        this.setState({
          showSystemMessage: true,
        });
      }

      if (!user.isOneTimeNotificationAccepted && !buyerView) {
        this.setState({
          firstTimePopup: !this.state.firstTimePopup,
        });
      }
      dispatch(hide_mobile_nav_menu(false));
      dispatch(remove_selected_mobile_car());
    }
  };

  componentDidMount = async () => {
    const { user, dispatch } = this.props;
    if (isIOS) {
      if (!isSafari && !isMobileSafari) {
        if (!this.props.nonSafariMobileAppDownloadNotificationModal.isSeen) {
          dispatch(
            non_safari_mobile_app_download_notification_modal({
              isHidden: false,
              isSeen: false,
            })
          );
        }
      } else if (!this.props.mobileAppDownloadInstructionsModal.isSeen) {
        await this.setupMobileDownloadModal(user);
      }
    }
  };

  setupMobileDownloadModal = async (user) => {
    const mobileCode = await redeemMobileCode(
      user,
      user.franchise === TBSG ? TDD : LDD
    );
    const url = API_URL.HOME_PAGE_ITUNES_URL + mobileCode;

    this.openDownloadAppInstructionModal(mobileCode, url);
  };

  openDownloadAppInstructionModal = (mobileCode, url) => {
    this.props.dispatch(
      mobile_app_download_instructions_modal({
        isHidden: false,
        mobileCode: mobileCode,
        url: url,
        isSeen: false,
      })
    );
  };

  componentDidUpdate = (prevProps) => {
    const { systemMessage } = this.props;
    if (this.props.cars.searchCars !== prevProps.cars.searchCars) {
      this.loadSearchCarDisplay();
    }
    if (this.props.cars.groundedCars !== prevProps.cars.groundedCars) {
      this.loadVehicleNeedingActionDisplay();
    }
    if (systemMessage !== prevProps.systemMessage) {
      this.setState({
        showSystemMessage: !this.state.showSystemMessage,
      });
    }
  };

  getGAMDVehicles = async () => {
    const { dispatch } = this.props;
    const { dealerId, userId } = this.props.user;
    const vehicles = await getGAMDVehicles(dealerId, userId);
    try {
      if (vehicles.status !== "false") {
        const vehiclesNeedingAttention = vehicles.filter(
          (vehicle) => vehicle.dealerFacingStatus === DLR_ACTION_NEEDED
        );
        this.setState({
          vehiclesNeedingAttention: vehiclesNeedingAttention,
        });
        dispatch(add_gamd_cars(vehicles));
      }
    } catch (err) {
      console.log(err);
      this.loadVehicleNeedingActionDisplay();
    }
  };

  firstTimePopupToggle = async () => {
    const { userId } = this.props.user;
    try {
      const message = await LoginServices.updateOneTimeNotification(userId);
      if (message === SUCCESS) {
        this.setState({
          firstTimePopup: !this.state.firstTimePopup,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  storeVehicleSearch = async () => {
    const { dispatch, user } = this.props;
    try {
      const vehicles = [].concat.apply(
        [],
        await getSavedAutoSearchResults(user)
      );
      const filteredVehicles = (array) => {
        const uniqueVins = [];
        const filteredVehicles = [];
        array.map((car) => {
          if (uniqueVins.indexOf(car.vin) < 0 && !car.message) {
            filteredVehicles.push(car);
            uniqueVins.push(car.vin);
          }
          return null;
        });
        return filteredVehicles;
      };
      if (vehicles.length > 0) {
        dispatch(add_search_cars(filteredVehicles(vehicles)));
      } else {
        this.loadSearchCarDisplay();
      }
    } catch (err) {
      console.log(err);
      this.loadSearchCarDisplay();
    }
  };

  tempVehicleSearch = async () => {
    const { user } = this.props;
    const searches = await getSavedSearch(user);
    let count = 0;
    for (let search of searches) {
      count += search.matchesCount;
    }
    this.setState({
      searchCount: count,
      searchCarLoad: true,
    });
  };

  getDealerPurchasePerformance = async () => {
    const { user } = this.props;
    try {
      const performance = await ReportServices.getDealerPurchasePerformance(
        user
      );
      this.setState({
        dealerPurchasePerformance: {
          dealerPenetrationStr: performance.dealerPenetrationStr,
          regionalPenetrationStr: performance.regionalPenetrationStr,
          nationalPenetrationStr: performance.nationalPenetrationStr,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadWatchlistData = async () => {
    const { user, dispatch } = this.props;
    const watchListCars = await WatchListServices.getSavedWatchlist(user);
    if (watchListCars && watchListCars.length !== undefined) {
      dispatch(watchlist_count(watchListCars.length));
    }
  };

  loadVehicleNeedingActionDisplay = () => {
    this.setState({
      attentionCarLoad: true,
    });
  };

  loadSearchCarDisplay = () => {
    this.setState({
      searchCarLoad: true,
    });
  };

  handleVinChange = (vin) => (event) => {
    this.setState({
      [vin]: event.target.value,
    });
  };

  handleVinSearch = () => {
    const { history } = this.props;
    const { vin } = this.state;
    history.push({ pathname: ROUTE_URL.GROUND, state: { vin: vin } });
  };

  advanceSearch = () => {
    const { history } = this.props;
    history.push(ROUTE_URL.ADVANCE_SEARCH);
  };

  handleMessageClose = () => {
    const { dispatch } = this.props;
    dispatch(system_message_viewed());
  };

  componentWillUnmount = () => {
    const { dispatch } = this.props;
    dispatch(add_search_cars([]));
  };

  savedSearchToggle = () => {
    const { history, dispatch } = this.props;
    dispatch(show_saved_search_page(true));
    history.push(ROUTE_URL.ADVANCE_SEARCH);
  };

  getVINDetails = async () => {
    const { loginId } = this.props.user;
    const { vin } = this.state;

    this.setState({ loading: true });
    let errorMessage = "";
    if (!loginId) {
      errorMessage = GROUNDING_MESSAGES.ERROR_NO_USER_AVAILABLE;
    } else if (!vin.trim()) {
      errorMessage = GROUNDING_MESSAGES.ERROR_VINS_IS_INVALID;
    } else {
      const vehicleDetails = await GroundingServices.vinDetails(
        vin,
        loginId,
        ""
      );
      if (!vehicleDetails) {
        errorMessage = GROUNDING_MESSAGES.ERROR_SOMETHING_WENT_WRONG;
      } else if (vehicleDetails.status === "false") {
        errorMessage = vehicleDetails.message;
      } else {
        this.loadPriceQuoteScreen();
      }
    }
    this.setState({
      loading: false,
      invalidVINErrorMessage: errorMessage,
      showInvalidVinErrorMessage: !!errorMessage,
    });
  };

  render() {
    const {
      classes,
      cars,
      accountSetting,
      history,
      dispatch,
      systemMessageViewed,
      user,
    } = this.props;
    const {
      searchCarLoad,
      attentionCarLoad,
      vehiclesNeedingAttention,
      vin,
      errorMessage,
      showSystemMessage,
    } = this.state;
    return (
      <React.Fragment>
        <div className={classes.homeWrapper}>
          <div className={classes.leftWrapper}>
            <Card className={classes.vinEntryWrapper} square>
              <div className={classes.groundTitleWithIcon}>
                <ReactSVG
                  svgStyle={{ width: "26px" }}
                  className={classes.groundIcon}
                  src={groundNavIcon}
                  alt="Grounding Navigation Icon"
                />
                <Typography className={classes.groundText}>
                  {GROUND_A_VEHICLE_HERE}
                </Typography>
              </div>
              <div className={classes.carCarousel}>
                <VINEntry
                  vin={vin}
                  errorMessage={errorMessage}
                  onVinChange={this.handleVinChange}
                  onVinSearch={this.handleVinSearch}
                  loadPriceQuoteScreen={this.getVINDetails}
                  home
                />
              </div>
              {this.state.showInvalidVinErrorMessage && (
                <Message
                  message={this.state.invalidVINErrorMessage}
                  variant="error"
                  hideIcon={true}
                  className={classes.vinInvalidError}
                />
              )}
            </Card>
            <Card className={classes.carWrapper} square>
              <div className={classes.titleWithIcon}>
                <ReactSVG
                  svgClassName={classes.icon}
                  svgStyle={{ width: "20px" }}
                  src={hourglass}
                  alt="Grounding Navigation Icon"
                  wrapper="span"
                />
                <Typography className={classes.carDisplayText}>
                  {VEHICLES_REQUIRING_ACTION}
                  {vehiclesNeedingAttention.length > 0 ? (
                    <Link
                      id="autoSearchLink"
                      className={classes.saveSearchLink}
                      component="button"
                      variant="body2"
                      onClick={() => {
                        dispatch(set_vehicles_needing_attention(true));
                        history.push(ROUTE_URL.GAMD);
                      }}
                    >
                      {" (" + vehiclesNeedingAttention.length + " vehicles)"}
                    </Link>
                  ) : null}
                </Typography>
              </div>
              <div className={classes.carCarousel}>
                {!attentionCarLoad ? (
                  <Loading />
                ) : (
                  <HomepageCarDisplay carList={vehiclesNeedingAttention} />
                )}
              </div>
            </Card>
            <Card className={classes.carWrapper} square>
              <div className={classes.titleWithIcon}>
                <ReactSVG
                  className={classes.iconAutoSearch}
                  src={searchIcon}
                  alt="Grounding Navigation Icon"
                />
                <Typography className={classes.carDisplayTextAutoSearch}>
                  {AUTOSEARCH_RESULTS}
                  {(cars.searchCars && cars.searchCars.length > 0) ||
                  this.state.searchCount > 0 ? (
                    <Link
                      id="autoSearchLink"
                      className={classes.saveSearchLink}
                      component="button"
                      variant="body2"
                      onClick={() => this.savedSearchToggle()}
                    >
                      {/* {`( ${this.state.searchCount} vehicles )`} */}
                      {` (` + cars.searchCars.length + ` vehicles)`}
                    </Link>
                  ) : null}
                </Typography>
              </div>
              <div className={classes.carCarousel}>
                {!searchCarLoad ? (
                  <Loading />
                ) : (
                  <HomepageCarDisplay
                    advanceSearch={this.advanceSearch}
                    carList={cars.searchCars}
                    from={"autoSearch"}
                  />
                )}
              </div>
            </Card>
          </div>
          <div className={classes.rightWrapper}>
            <DealerPurchasePerformance
              dealerPurchasePerformance={this.state.dealerPurchasePerformance}
            />
            <MessageCenter accountSetting={accountSetting} />
          </div>
        </div>
        <Snackbar
          open={showSystemMessage && !systemMessageViewed}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={this.handleMessageClose}
          classes={{ anchorOriginTopLeft: classes.anchorOriginTopLeft }}
        >
          <SnackbarContent
            className={classes.warning}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <WarningIcon className={classes.warningIcon} />
                {user.systemMessage}
              </span>
            }
            action={[
              <IconButton
                className={classes.systemMessageLabel}
                key="close"
                aria-label="close"
                color="inherit"
                onClick={this.handleMessageClose}
              >
                <CloseIcon className={classes.icon} />
              </IconButton>,
            ]}
          />
        </Snackbar>
        <NonSafariIOSAppDownloadNoticeModal />
        <AppDownloadModal />
      </React.Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user,
    cars: state.cars,
    accountSetting: state.accountSetting,
    GAMDConditionReports: state.cars.GAMDConditionReports,
    navLocation: state.ui.navLocation,
    pageTheme: state.ui.pageTheme,
    buyerView: state.ui.buyerView,
    systemMessageViewed: state.ui.systemMessageViewed,
    nonSafariMobileAppDownloadNotificationModal:
      state.ui.nonSafariMobileAppDownloadNotificationModal,
    mobileAppDownloadInstructionsModal:
      state.ui.mobileAppDownloadInstructionsModal,
  };
};
export default connect(mapStateToProps)(withStyles(styles)(Home));
